<template>
  <div class="reg-container">
    <table class="tb1">
      <tr>
        <td class="td1">单位名称</td>
        <td class="td2" colspan="3">{{reg.name}}</td>
        <td class="td1">电话</td>
        <td class="td2">{{reg.phone}}</td>
      </tr>
      <tr>
        <td class="td1">地址</td>
        <td class="td2" colspan="3">{{reg.address}}</td>
        <td class="td1">邮编</td>
        <td class="td2">{{reg.postcode}}</td>
      </tr>
      <tr>
        <td class="td3">法定代表人或主要负责人</td>
        <td class="td4">{{reg.legalName}}</td>
        <td class="td3">消防安全管理人</td>
        <td class="td4">{{reg.fireSafeManagePerson}}</td>
        <td class="td3">职工人数（人）</td>
        <td class="td4">{{reg.employeeNum}}</td>
      </tr>
      <tr>
        <td class="td3">有无自动消防设施</td>
        <td class="td4">
          <el-radio-group v-model="reg.autoFireFacility">
            <el-radio v-for="(item,i) in [{id:0,label:'是'},{id:1,label:'否'}]" :key="i" :label="item.id">
              {{item.label}}
            </el-radio>
          </el-radio-group>
        </td>
        <td class="td3">固定资产（万元）</td>
        <td class="td4">{{reg.fixAsset}}</td>
        <td class="td3">建筑面积（㎡）</td>
        <td class="td4">{{reg.area}}</td>
      </tr>
      <tr>
        <td class="td3">单位性质</td>
        <td colspan="5" class="td4">
          <el-radio-group v-model="reg.unitNature">
            <el-radio v-for="(item,i) in unitNatureList" :key="i" :label="item.id">
              {{item.text}}
            </el-radio>
          </el-radio-group>
        </td>
      </tr>
      <tr>
        <td rowspan="3" class="td3" style="border-right: 1px solid #ddd;">单位消防行政许可情况</td>
        <td colspan="2" class="td3">消防设计审核验收</td>
        <td colspan="3" class="td4">
          <el-checkbox-group v-model="reg.checkAccept">
            <el-checkbox v-for="(item,i) in [{id:0,label:'已取得设计审核许可'},{id:1,label:'取得消防设计验收许可'}]" :key="i" :label="item.id">
              {{item.label}}
            </el-checkbox>
          </el-checkbox-group>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="td3">消防设计、竣工验收消防备案</td>
        <td colspan="3" class="td4">
          <el-checkbox-group v-model="reg.acceptRecord">
            <el-checkbox v-for="(item,i) in [{id:0,label:'已取得设计备案'},{id:1,label:'取得消防设计备案'}]" :key="i" :label="item.id">
              {{item.label}}
            </el-checkbox>
          </el-checkbox-group>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="td3">投入使用、营业前消防安全检查</td>
        <td colspan="3" class="td4">
          <el-checkbox-group v-model="reg.safeCheck">
            <el-checkbox v-for="(item,i) in [{id:0,label:'已通过消防安全检查'},{id:1,label:'未通过消防安全检查'}]" :key="i" :label="item.id">
              {{item.label}}
            </el-checkbox>
          </el-checkbox-group>
        </td>
      </tr>
    </table>
    <table class="unit-attr">
      <tr>
        <td rowspan="13" class="td1">
          申<br><br>报<br><br>单<br><br>位<br><br>属<br><br>性
        </td>
        <td class="td2 bg">主类</td>
        <td class="td3 bg">分类</td>
      </tr>
      <tr v-for="(item,i) in unitAttrList" :key="i">
        <td class="td2">
          <el-radio v-model="reg.mainClass" :label="item.id" name="mainClass">{{null}}</el-radio>{{item.name}}
        </td>
        <td class="td3">
          <el-checkbox-group v-model="reg.classify">
            <el-checkbox v-for="subitem in item.classifyList" :key="subitem.id" :label="subitem.id" style="width:100%">
              {{subitem.classifyName}}
            </el-checkbox>
          </el-checkbox-group>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      unitAttrList: [],
      unitNatureList: [],
      reg: {
        classify: [],
        checkAccept: [],
        acceptRecord: [],
        safeCheck: []
      }
    }
  },
  created () {
    this.getUnitAttr()
    this.getUnitNature()
    this.getRegisterInfo()
  },
  methods: {
    async getUnitAttr () {
      const { data: result } = await this.$axios.get('/fireImportanceUnitApplyForm/listAllUnitProperty')
      if (result.code === 200) {
        this.unitAttrList = result.data
      }
    },
    async getUnitNature () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'unitNature' } })
      if (result.code === 200) {
        this.unitNatureList = result.data
      }
    },
    async getRegisterInfo () {
      const data = {}
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      const { data: result } = await this.$axios.get('/fireImportanceUnitRegisterForm/list', { params: data })
      console.log(result)
      if (result.code === 200) {
        if (result.data.length > 0) {
          result.data[0].classify = result.data[0].classify.split(',').map(Number)
          this.reg = result.data[0]
        }
      } else {
        this.$message.error(result.msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reg-container {
  width: 100%;
  height: 100%;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  color: #fff;
  .tb1 {
    width: 100%;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    box-sizing: border-box;
    .el-checkbox,
    .el-radio {
      color: #fff;
    }
    td {
      height: 40px;
      color: #fff;
    }
    .td1 {
      width: 16%;
      background-color: rgba(30, 74, 115, 0.5);
      border-bottom: 1px solid #ccc;
      box-sizing: border-box;
    }
    .td2 {
      width: 20%;
      border: 1px solid #ccc;
      border-top: none;
      box-sizing: border-box;
    }
    .td3 {
      width: 16.6%;
      background-color: rgba(30, 74, 115, 0.5);
      border-bottom: 1px solid #ccc;
    }
    .td4 {
      width: 16.6%;
      border: 1px solid #ccc;
      border-top: none;
    }
  }
  .unit-attr {
    width: 100%;
    border: 1px solid #ddd;
    border-top: none;
    box-sizing: border-box;
    border-collapse: collapse;
    table-layout: fixed;
    word-break: break-all;
    white-space: normal;
    .bg {
      background-color: rgba(30, 74, 115, 0.5);
      text-align: center !important;
    }
    td {
      height: 40px;
    }
    .td1 {
      font-size: 18px;
      width: 20%;
      border-right: 1px solid #ddd;
      background-color: rgba(30, 74, 115, 0.5);
    }
    .td2 {
      width: 30%;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      text-align: left;
      padding: 5px;
      box-sizing: border-box;
    }
    .td3 {
      width: 50%;
      border-bottom: 1px solid #ddd;
      text-align: left;
      padding: 5px;
      box-sizing: border-box;
      .el-checkbox {
        margin-bottom: 5px;
        color: #fff;
      }
    }
  }
}
</style>
